import React, { useEffect, useState } from "react";
import {
  Button, Card, CardBody, CardTitle, Col, Container, Modal, ModalHeader, Row, Spinner 
} from "reactstrap";
import AuthCode from "react-auth-code-input";
import MetaTags from "react-meta-tags";
//i18n
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generateQRCodeStart, verify2FACodeStart } from "store/actions";


function TwoFAModal(props) {
  const [twoFACode, setTwoFACode] = useState();
  const [showVerification, setShowVerification] = useState(false);
  const { userData, settings } = useSelector((state) => ({
    userData: state.Profile.userData,
    settings: state.Profile.settings,
  }));
  const { verifyCode, generateQR } = useSelector(
    (state) => state.twoFactorAuthReducer
  );
  const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const focusInput2FA = (digits) => {
    const activeInputs = document.querySelectorAll(".twofa-input.active");
    const inputs = document.querySelectorAll(".twofa-input");
    if (activeInputs.length > digits.toString().length)
      activeInputs[activeInputs.length - 1]?.classList.remove("active");
    else inputs[digits.toString().length - 1]?.classList.add("active");
    setTwoFACode(digits);
    if (digits.length === 6) enableDisableTwoFactorAuth(digits);
  };

  useEffect(() => {
    if (generateQR && generateQR.qrCode) {
      setShowVerification(true);
    } else {
      setShowVerification(false);
    }
  }, [generateQR.qrCode]);

  useEffect(() => {
    if (verifyCode?.result?.type === "disable" && verifyCode.success) {
      setTwoFactorAuthEnabled(false);
    }
    if (verifyCode?.result?.type === "enable" && verifyCode.success) {
      setTwoFactorAuthEnabled(true);
    }
  }, [verifyCode]);


  useEffect(() => {
    if (settings.twoFactorAuthEnabled) setTwoFactorAuthEnabled(true);
  }, [settings.twoFactorAuthEnabled]);

  useEffect(() => {
    if (verifyCode.disabled) setShowVerification(false);
  }, [verifyCode.disabled]);

  const enable2FA = () => dispatch(generateQRCodeStart());
  const disable2FA = () => setShowVerification(true);
  const enableDisableTwoFactorAuth = (code) =>
    twoFactorAuthEnabled
      ? dispatch(
        verify2FACodeStart({
          code: code || twoFACode,
          type: "disable",
          email: userData.email,
        })
      )
      : dispatch(
        verify2FACodeStart({
          code: code || twoFACode,
          type: "enable",
          email: userData.email,
        })
      );
  const getCardData = () => {
    let buttonClick = enable2FA;
    let disabled = verifyCode.loading;
    let buttonText = t("Enable");
    let topText = t("Disabled");
    let buttonClass = "btn-success";
    let cdaLoading = generateQR.loading;
    if (twoFactorAuthEnabled) {
      buttonClick = disable2FA;
      disabled = verifyCode.loading;
      buttonText = t("Disable");
      topText = t("Enabled");
      buttonClass = "btn-danger";
      cdaLoading = verifyCode.loading;
    }
    return (
      <div className="text-center my-3">
        <h4>Two Factor Auth ({topText})</h4>
        <Button
          className={buttonClass}
          onClick={buttonClick}
          disabled={disabled}
        >
          {cdaLoading ? <Spinner /> : buttonText}
        </Button>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        // toggle={() => props.toggle()}
        centered={true}
        size="lg"
        className='custom-modal'
      >
        <div style={{
          padding: 20,
        }}>
          <ModalHeader className="d-flex flex-column gap-3">
            <div className="text-center">
              <CardTitle className="mb-0">{props.t("Two Factor Authentication")}</CardTitle>
            </div>
          </ModalHeader>
          <CardBody>
            {getCardData()}
            <Container>
              {generateQR?.qrCode && (
                <Card className="pb-3">
                  <Row className="justify-content-center">
                    <Col className="text-center" lg={6}>
                      {generateQR.loading ? (
                        <Spinner></Spinner>
                      ) : (
                        <img
                          src={generateQR.qrCode}
                          alt="qr-code"
                          width={200}
                          height={200}
                        />
                      )}
                      <p>
                        {t(
                          "Use the authencator app on your phone to scan the QR code above."
                        )}
                      </p>
                    </Col>
                  </Row>
                </Card>
              )}
              {showVerification && (
                <Card className="py-3 text-center">
                  <AuthCode
                    characters={6}
                    allowedCharacters="^[0-9]"
                    containerClassName="twofa-container"
                    inputClassName="twofa-input"
                    onChange={(a) => {
                      focusInput2FA(a);
                    }}
                  ></AuthCode>
                  <div>
                    <Button
                      color="primary"
                      className="mt-3 w-md"
                      onClick={enableDisableTwoFactorAuth}
                      disabled={verifyCode.loading}
                    >
                      {t("Verify")}
                    </Button>
                  </div>
                </Card>
              )}
            </Container>
          </CardBody>
        </div>
      </Modal>
    </React.Fragment>
  );
}
export default withTranslation()(TwoFAModal); 